import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { injectIntl } from "gatsby-plugin-intl"
import BelgiumFlag from "../images/BelgiumFlag.png"

const Story = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      GaufreStory1: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      },
      GaufreStory2: file(relativePath: { eq: "ingredients.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      },
      GaufreStory3: file(relativePath: { eq: "micfull-min.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <div className="uk-section" id="secret">
      <div className="uk-container uk-padding@s uk-margin-large-top uk-margin-large-bottom">
        <div className="uk-width-2-3@m">
          <h1>{intl.formatMessage({ id: "recipe-section-headline" })}</h1>
        </div>
        <div className="uk-padding-small">
          <div class="uk-flex-middle uk-padding@s" data-uk-grid>
            <div class="uk-width-1-3@s">
              <Img fluid={data.GaufreStory3.childImageSharp.fluid} alt="Gaufres Bonbec"/>
            </div>
            <div class="uk-width-2-3@s uk-padding-large uk-flex-first@s uk-text-center ">
              <h2>{intl.formatMessage({ id: "recipe-section-title-1" })} <img className="belgium-icon uk-margin-small-bottom" src={BelgiumFlag} alt="Belgium flag"/></h2>
              <h4>{intl.formatMessage({ id: "recipe-section-content-1" })} <strong>{intl.formatMessage({ id: "recipe-section-tagline-1" })}</strong></h4>
            </div>
          </div>
          <div class="uk-flex-middle uk-padding@s" data-uk-grid>
          <div class="uk-width-1-3@s uk-flex-first@s">
            <Img fluid={data.GaufreStory1.childImageSharp.fluid} alt="Gaufres Bonbec"/>
          </div>
            <div class="uk-width-2-3@s uk-padding-large uk-flex-first@s uk-text-center">
              <h2>{intl.formatMessage({ id: "recipe-section-title-2" })}</h2>
              <h4>{intl.formatMessage({ id: "recipe-section-content-2" })} <strong>{intl.formatMessage({ id: "recipe-section-tagline-2" })}</strong></h4>
            </div>
          </div>
          <div class="uk-flex-middle uk-padding@s" data-uk-grid>
            <div class="uk-width-1-3@s uk-padding-large@s">
              <Img fluid={data.GaufreStory2.childImageSharp.fluid} alt="Gaufres Bonbec"/>
            </div>
            <div class="uk-width-2-3@s uk-padding-large uk-flex-first@s uk-text-center ">
              <h2>{intl.formatMessage({ id: "recipe-section-title-3" })}</h2>
              <h4>{intl.formatMessage({ id: "recipe-section-content-3" })} <strong>{intl.formatMessage({ id: "recipe-section-tagline-3" })}</strong></h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  };
export default injectIntl(Story)
