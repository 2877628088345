import React from 'react'
import { injectIntl } from "gatsby-plugin-intl"

import Products from "../components/products"
import Delivery from "../components/delivery"
import Discuss from "../components/discuss"
import Cart from "../components/cart"
import Summary from "../components/summary"
import CheckoutOrder from "../components/contact"


function receiptCalculator(productList, totalCodePromo){
  // Waffles + (map)
  var totalBeforeTaxes = 0;
  var totalWithTaxes = 0;
  var totalTaxes = 0;
  var totalQuantity = 0;

  var listItems = '\n--------------------------------------------\n';

  // Discount and Promo -
  var totalDiscount = 0;
  var totalCodePromo = parseInt(totalCodePromo);

  var updatedReceipt = productList.map((product) => {
    if (product.qty > 0) {
      totalQuantity += product.qty;
      var totalWithTaxesItem = (product.qty * product.price);
      totalWithTaxes += totalWithTaxesItem;
      totalBeforeTaxes += (totalWithTaxesItem * .85);
      totalTaxes += (totalWithTaxesItem * .15);
      totalDiscount += product.discount;
      listItems += product.name+' - '+product.qty+' x $'+product.price+' (total: $'+totalWithTaxesItem+') \n'
    }
  });

  listItems += '--------------------------------------------\n'

  // Delivery + 2.55
  if (totalQuantity > 0) {
    var deliveryFee = (3);
  } else {
    var deliveryFee = (0);
  }
  var deliveryFeeBeforeTax = (deliveryFee * .85);
  totalTaxes += (deliveryFee * .15);

  totalWithTaxes += deliveryFee;

  var totalFinal = (totalWithTaxes - (totalDiscount + totalCodePromo))

  if (totalFinal < 0) {
    totalFinal = 0;
    totalWithTaxes = 0
  }

  var receipt = {
  totalBeforeTaxes: totalBeforeTaxes.toFixed(2),
  deliveryFeeBeforeTax: deliveryFeeBeforeTax.toFixed(2),
  totalTaxes: totalTaxes.toFixed(2),
  totalWithTaxes: totalWithTaxes.toFixed(2),
  totalDiscount: totalDiscount.toFixed(2),
  totalCodePromo: totalCodePromo.toFixed(2),
  totalFinal: totalFinal.toFixed(2),
  totalQuantity: totalQuantity.toFixed(2),
  listItems: listItems
  };

  return receipt;
}


function handleEmailSummary(receipt) {
  var emailSummary = '';

  emailSummary += 'Reçu / Receipt';
  emailSummary += receipt.listItems;
  emailSummary += 'Total avant taxes: $'+receipt.totalBeforeTaxes+'\n';
  emailSummary += 'Frais de livraison (à vélo): $'+receipt.deliveryFeeBeforeTax+'\n';
  emailSummary += 'Total taxes: $'+receipt.totalTaxes+'\n';
  emailSummary += 'Total avec taxes: $'+receipt.totalWithTaxes+'\n';
  emailSummary += 'Total rabais: -$'+receipt.totalDiscount+'\n';
  emailSummary += 'Total code promo: -$'+receipt.totalCodePromo+'\n';
  emailSummary += 'Total final: $'+receipt.totalFinal+'\n';

  localStorage.setItem('order', emailSummary);

  return emailSummary

}


function handleDiscount(updatedProductQty) {
  return (Math.floor(((updatedProductQty) / 4))*2);
}

function handleTotal(updatedProductList) {
  let total = 0;
  var calculateTotal = updatedProductList.map((product) => {
    var productTotal = (product.price * product.qty)
    total += productTotal;
  });
  return total
}

function handleTotalProducts(updatedProductList) {
  let totalProducts = 0;
  var calculateTotalProducts = updatedProductList.map((product) => {
    totalProducts += product.qty;
  });
  localStorage.setItem('totalProducts', totalProducts);
  return totalProducts
}

function handleTotalDiscount(updatedProductList) {
  let totalDiscount = 0;
  var calculateTotalDiscount = updatedProductList.map((product) => {
    totalDiscount = (totalDiscount + product.discount);
  });
  return totalDiscount
}


function handleResetCart(cart) {
  var resettedProductList = cart.map((product) => {
      product.qty = 0;
      product.discount = 0;
      return product;
  });
  return resettedProductList;
}

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.queryString = this.queryString.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleSubstract = this.handleSubstract.bind(this);
    this.handleShowCart = this.handleShowCart.bind(this);
    this.handleOrderSubmit = this.handleOrderSubmit.bind(this);
    this.handleNewOrder = this.handleNewOrder.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCode = this.handleCode.bind(this);
    if (typeof window !== 'undefined') {
      if (localStorage.getItem('totalPromo')) {
        var totalPromo = localStorage.getItem('totalPromo');
        var code = localStorage.getItem('totalPromo');
        var codeApplied = localStorage.getItem('totalPromo')
        var codeMessage = localStorage.getItem('codeMessage')
      } else {
        var totalPromo = 0;
        var code = '';
        var codeApplied = false;
      }
    } else {
      var totalPromo = 0;
      var code = '';
      var codeApplied = false;
      var codeMessage = '';
    }
    this.state = {
      productList: this.props.productList,
      receipt: receiptCalculator(this.props.productList, totalPromo),
      total: 0,
      totalDiscount: 0,
      totalPromo: totalPromo,
      totalProducts: 0,
      showCart: true,
      showCartButton: true,
      showNewOrder: true,
      showCheckout: true,
      orderSubmitted: false,
      emailSummary: '',
      width: 0,
      code: code,
      codeApplied: codeApplied,
      codeMessage: codeMessage,
      totalPromo: 0
    };
    this.ref = React.createRef();

  }

  queryString(){
    this.state = {
      showCart: true,
      showCartButton: true,
      showNewOrder: true,
      showCheckout: true
    };
  }


  componentDidMount() {
    if (typeof window !== 'undefined') {
      if (localStorage.getItem('totalPromo')) {
        var totalPromo = localStorage.getItem('totalPromo');
        var code = localStorage.getItem('totalPromo');
        var codeApplied = localStorage.getItem('totalPromo')
        var codeMessage = localStorage.getItem('codeMessage')
      } else {
        var totalPromo = 0;
        var code = '';
        var codeApplied = false;
      }
    } else {
      var totalPromo = 0;
      var code = '';
      var codeApplied = false;
      var codeMessage = '';
    }

    if (localStorage.getItem('productList') != null) {
      let productList = JSON.parse(localStorage.getItem('productList'));
      this.setState({
        productList: productList,
        receipt: receiptCalculator(productList, totalPromo),
        totalPromo: totalPromo,
        total: handleTotal(productList),
        totalDiscount: handleTotalDiscount(productList),
        totalProducts: handleTotalProducts(productList),
        emailSummary: handleEmailSummary(receiptCalculator(productList, totalPromo)),
        showCart: true,
        showCartButton: true,
        showCheckout: true,
        code: code,
        codeApplied: codeApplied,
        codeMessage: codeMessage,
        totalPromo: 0
      });
    }
    if (this.props.queryString === "?pourlesfetes") {
      this.setState({
        showCart: true,
        showCartButton: true,
        showCheckout: true,
        canOrder: true
      });
    } else {
      this.setState({
        showCart: false,
        showCartButton: false,
        showCheckout: false,
        canOrder: false
      });
    }

  }

  handleAdd(updatedProduct) {
    var productList = this.state.productList;
    if (localStorage.getItem('productList') != null) {
      productList = localStorage.getItem('productList');
    }
    productList = this.state.productList;
    var updatedProductList = productList.map((product) => {
      if (product.name === updatedProduct.name) {
        product.qty = updatedProduct.qty + 1;
        product.discount = handleDiscount(updatedProduct.qty);
        return product;
      } else {
        return product;
      }
    });
    localStorage.setItem('productList', JSON.stringify(updatedProductList));
    this.setState({
      productList: updatedProductList,
      receipt: receiptCalculator(updatedProductList, this.state.totalPromo),
      total: handleTotal(updatedProductList),
      totalDiscount: handleTotalDiscount(updatedProductList),
      totalProducts: handleTotalProducts(updatedProductList),
      emailSummary: handleEmailSummary(receiptCalculator(updatedProductList, this.state.totalPromo)),
      showCart: true,
      showCartButton: true,
      showCheckout: true
    });
  }

  handleSubstract(updatedProduct) {
    var updatedProductList = this.state.productList.map((product) => {
      if (product.name === updatedProduct.name) {
        product.qty = updatedProduct.qty - 1;
        product.discount = handleDiscount(updatedProduct.qty);
        return product;
      } else {
        return product;
      }
    });
    localStorage.setItem('productList', JSON.stringify(updatedProductList));
    this.setState({
      productList: updatedProductList,
      receipt: receiptCalculator(updatedProductList, this.state.totalPromo),
      total: handleTotal(updatedProductList),
      totalDiscount: handleTotalDiscount(updatedProductList),
      totalProducts: handleTotalProducts(updatedProductList),
      emailSummary: handleEmailSummary(receiptCalculator(updatedProductList, this.state.totalPromo)),
    });
  }

  scrollToNode(){
    this.setState({
      showCart: true
    }, function() {
      this.ref.current.scrollIntoView({
          behavior: 'smooth'
        });
    })
  }

  handleOrderSubmit(){
    this.setState({
      showCart: false,
      showCheckout: true,
      orderSubmitted: true
    }, function() {
      setTimeout(() => {
        localStorage.removeItem('totalProducts');
        this.ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
      }, 50);
    })
  }

  handleShowCart(){
    this.setState({
      showCart: true,
      showCheckout: true
    }, function() {
      setTimeout(() => {
        this.ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
      }, 50);
    })
  }

  handleNewOrder(){
    localStorage.removeItem('productList');
    var resettedProductList = handleResetCart(this.state.productList);
    this.setState({
      productList: resettedProductList,
      receipt: receiptCalculator(resettedProductList, 0),
      total: handleTotal(resettedProductList),
      totalDiscount: handleTotalDiscount(resettedProductList),
      emailSummary: handleEmailSummary(receiptCalculator(resettedProductList, this.state.totalPromo)),
      showCart: true,
      showCheckout: true,
      totalPromo: 0,
    });
  }



  handleChange(e){
    this.setState({
      [e.target.name]: e.target.value,
     });
  };

  handleCode(e){
    var productList = this.state.productList;
    if (this.state.code.toUpperCase() === "LIVRAISON") {
      this.setState({
  			codeApplied: true,
        codeMessage: "valid",
        totalPromo: 3,
        receipt: receiptCalculator(productList, 3),
        emailSummary: handleEmailSummary(receiptCalculator(productList, 3)),
  		 });
      localStorage.setItem('totalPromo', 3);
  		localStorage.setItem('codeApplied', true);
    } else {
      this.setState({
  			codeApplied: false,
        codeMessage: "invalid",
        totalPromo: 0,
        receipt: receiptCalculator(productList, 0),
        emailSummary: handleEmailSummary(receiptCalculator(productList, 0)),
  		 });
       localStorage.setItem('totalPromo', 0);
   	 	 localStorage.setItem('codeApplied', false);
    }
  }

  render() {
    const productList = this.state.productList;
    const receipt = this.state.receipt;
    const total = this.state.total;
    const translations = this.props.translations;
    const images = this.props.images;
    const intl = this.props.intl;

    var component = this;

    var products = productList.map(function(product) {
      return (
        <>
        <Products
          product={product}
          translations={translations}
          intl={intl}
          onAdd={component.handleAdd}
          onSubstract={component.handleSubstract}
        />
        </>
      );
    });

    var cart = this.state.productList.map(function(product) {
      return (
        <>
        <Cart
          product={product}
          translations={translations}
          intl={intl}
          onAdd={component.handleAdd}
          onSubstract={component.handleSubstract}/>
        </>
      );
    });


    return (

      <div>

        <div className="uk-section inverted uk-padding@s" id="gaufres">
            <div className="uk-container">
            <div class="uk-text-center uk-margin-bottom" data-uk-grid>
                <div class="uk-width-2-5@s uk-text-left">
                <h1>{translations.wafflesHeadline}</h1>
                </div>
                <div class="uk-width-expand@s uk-text-right">
                </div>
                <div class="uk-width-auto@s uk-text-right uk-visible@s">
                <div class="circle circle-ctn" id="circle-ctn"><span>{intl.formatMessage({ id: "ingredients-tag" })}</span></div>
                </div>
            </div>
              <div class="uk-flex uk-flex-middle uk-child-width-1-2@m uk-child-width-1-2@s uk-padding" data-uk-grid data-uk-height-match="target: > div > div > .match-height-disabled">
              {products}
              </div>
              { this.state.showCartButton && this.state.canOrder &&
              <div class="uk-flex uk-flex-center">
                  <button class="uk-button uk-button-default submit-button" href="#commander" onClick={this.handleShowCart}>{intl.formatMessage({ id: "review-order" })}</button>
              </div>
               }
            </div>
        </div>
        <div className="uk-section uk-padding@s" id="gouter">
          { this.state.canOrder &&
          <div className="uk-container">
            <div className="uk-width-1-3@m">
              <h1>{intl.formatMessage({ id: "delivery-section-headline" })}</h1>
            </div>
            <div className="uk-padding-small">
              <Delivery
                intl={intl}
                images={images}
                onOrderSubmit={component.handleOrderSubmit}
                onShowCart={component.handleShowCart}
                showCart={this.state.showCart}
              />
              <div ref={this.ref}></div>
            </div>
          </div>
        }
        { !this.state.canOrder &&
          <div className="uk-container">
            <div className="uk-width-1-3@m">
              <h1>{intl.formatMessage({ id: "delivery-section-headline" })}</h1>
            </div>
            <div className="uk-padding-small">
              <Discuss
                intl={intl}
                images={images}
                onOrderSubmit={component.handleOrderSubmit}
                onShowCart={component.handleShowCart}
                showCart={this.state.showCart}
              />
              <div ref={this.ref}></div>
            </div>
          </div>
        }
        </div>

        <div className="uk-section inverted" id="commander"  >
        { this.state.showCart &&
          <div className="uk-container uk-padding@s uk-margin-large-top uk-margin-remove-top">
          <div class="uk-text-center uk-margin-bottom" data-uk-grid>
              <div class="uk-width-1-5@s uk-text-left">
              <h1>{intl.formatMessage({ id: "summary" })}</h1>
              </div>
              <div class="uk-width-expand@s uk-text-right">
              </div>
              <div class="uk-width-auto@s uk-text-right uk-visible@s">
              <div class="starburst starbust-ctn" id="starbust-ctn"><span>{intl.formatMessage({ id: "tax-tag" })}</span></div>
              </div>
          </div>
            <div className="uk-padding-small@s">
              <>
              <div class="uk-flex-top uk-padding@s" id="panier" data-uk-grid>
                <div class="uk-width-3-3@m uk-margin-top">
                  <table class="uk-table uk-table-middle uk-table-divider">
                  <thead>
                      <tr>
                          <th className="uk-visible@s"></th>
                          <th className="uk-text-center">{translations.waffles}</th>
                          <th className="uk-text-center">{translations.price}</th>
                          <th className="uk-text-center">{translations.quantity}</th>
                          <th className="uk-text-right">{translations.totalWithTaxes}</th>
                      </tr>
                  </thead>
                  <tbody>
                  {cart}
                  </tbody>
                  </table>
                </div>
                <div class="uk-width-3-3@m uk-margin-large uk-margin-remove-bottom">
                <p className="uk-text-muted">{intl.formatMessage({ id: "code-promo" })}</p>
                <div class="uk-grid-small uk-text-center uk-margin-bottom" data-uk-grid>

                    <div class="uk-width-1-5@m uk-text-left@m">
              				<div class="uk-form-controls">
              						<input class="uk-input promo-code" type="text" name="code" onChange={this.handleChange}/>
              				</div>
                    </div>
                    <div class="uk-width-expand@m uk-text-left@m">
                      <div class="uk-form-controls">
                        <button class="uk-button uk-button-default" onClick={this.handleCode}>{intl.formatMessage({ id: "apply" })}</button>
                        <span className="uk-text-success">&nbsp;&nbsp;{this.state.codeMessage === "valid" && "  Code appliqué  ✓"}{this.state.codeMessage === "invalid" && "  Code non valide  ✗"}</span>
                      </div>
                    </div>
                    <div class="uk-width-2-5@m uk-text-right@m">
                      <div class="uk-form-controls">
                        <button class="uk-button uk-button-default" onClick={this.handleNewOrder}>{intl.formatMessage({ id: "reset" })}</button>
                      </div>
                    </div>
                </div>
                <Summary
                  products={productList}
                  receipt={receipt}
                  total={this.state.total}
                  totalDiscount={this.state.totalDiscount}
                  totalPromo={this.state.totalPromo}
                  translations={translations}
                  intl={intl}
                  onAdd={component.handleAdd}
                  onSubstract={component.handleSubstract}
                />
                </div>
              </div>
              </>
            </div>
          </div>
            }
        </div>
        { this.state.showCheckout &&
        <div className="uk-section uk-padding@s" id="checkout">
          <div className="uk-container">
            <div className="uk-padding-small@s">
              <>
              <div class="uk-flex-top">
                <h1>{intl.formatMessage({ id: "checkout" })}</h1>
                <div class="uk-width-3-3@m">
                <CheckoutOrder
                intl={intl}
                emailSummary={this.state.emailSummary}
                onOrderSubmit={component.handleOrderSubmit}
                showNewOrder={this.state.showNewOrder}
                onNewOrder={component.handleNewOrder}/>
                </div>
              </div>
              </>
            </div>
          </div>
        </div>
        }
      </div>

    );
  }
}
export default injectIntl(Order)
