import React from 'react'
import Img from "gatsby-image"
import { injectIntl } from "gatsby-plugin-intl"


class Delivery extends React.Component {
    constructor(props) {
      super(props);
      this.handleShowCart = this.handleShowCart.bind(this);
    }

     handleShowCart() {
        this.props.onShowCart();
      }

    render() {
      const intl = this.props.intl;
      const images = this.props.images;
      return (
        <>
        <div class="uk-flex-middle" data-uk-grid>
          <div class="uk-width-1-3@s">
            <Img fluid={images.imageBox} alt="Gaufres Bonbec" />
          </div>
          <div class="uk-width-2-3@s uk-padding-large uk-flex-first@s uk-text-center ">
              <h2>{intl.formatMessage({ id: "delivery-section-title" })}</h2>
              <p>{intl.formatMessage({ id: "delivery-section-content-1"})}</p>
              <p>{intl.formatMessage({ id: "delivery-section-content-2"})}</p>
              <p>{intl.formatMessage({ id: "delivery-section-content-3"})}</p>
              <div class="uk-flex uk-flex-center" data-uk-grid>
                <div class="uk-width-2-3@s">
                { !this.props.showCart ?
                  <button class="uk-button uk-button-default submit-button" href="#commander" onClick={this.handleShowCart}>{intl.formatMessage({ id: "choose" })}</button>
                :
                  <button class="uk-button uk-button-default submit-button" href="#commander" onClick={this.handleShowCart}>{intl.formatMessage({ id: "review-order" })}</button>
                }
                </div>
              </div>
            </div>
        </div>
        </>
        );
      }
}

export default Delivery;
