import React from 'react';
import Img from "gatsby-image"
import Addicon from "../components/addicon"

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleSubstract = this.handleSubstract.bind(this);
  }

  handleAdd(e) {
    this.props.onAdd(e);
  }

  handleSubstract(e) {
    this.props.onSubstract(e);
  }

  render() {
    const product = this.props.product;
    const translations = this.props.translations;
    const intl = this.props.intl;
    return (

      <div key={product.id}>
      <div class="uk-card uk-card-default">
          <div className="match-height">
              <div class="uk-card-media-top">
                  <Img fluid={product.image} alt="Gaufres Bonbec" />
              </div>
              <div class="uk-card-body gaufre">
                  <h2 class="uk-card-title">{product.name}</h2>
                  <p className="recipe-content">{product.description}</p>
              </div>
          </div>
          <div class="uk-padding">
              <div class="uk-flex uk-flex-center" data-uk-grid>
                  <div>
                  <p className="uk-text-small pricing">{product.price_label}</p>
                    <div class="quantity">
                      <a onClick={(e)=> this.handleAdd(product)}>
                      <div className="addIcon">
                        <Addicon/>
                      </div>
                      </a>
                      <a onClick={(e)=> this.handleAdd(product)}>
                      <input readOnly value={product.qty > 1 ? product.qty+" "+translations.waffles+"s" : product.qty+" "+translations.waffles}/>
                      </a>
                      <div class="quantity-nav">
                          <a class="quantity-button quantity-up" onClick={(e)=> this.handleAdd(product)}>+</a>
                          <a class={product.qty < 1 ? "quantity-button quantity-down disabled" : "quantity-button quantity-down active"} onClick={(e)=> this.handleSubstract(product)} disabled={product.qty < 1}>-</a>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
    );
  }
}

export default Products;
