import React from 'react'
import Img from "gatsby-image"
import { injectIntl } from "gatsby-plugin-intl"


class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleSubstract = this.handleSubstract.bind(this);
  }

  handleAdd(e) {
    this.props.onAdd(e);
  }

  handleSubstract(e) {
    this.props.onSubstract(e);
  }

  render() {
    const product = this.props.product;
    const translations = this.props.translations;
    const intl = this.props.intl;
    var totalWithTax = ((product.price*product.qty).toFixed(2));
    var totalWithDiscount = ((totalWithTax-product.discount).toFixed(2));
    var discount = product.discount.toFixed(2);
    return (
      <tr key={product.id}>

           <>
               <td className="thumbnail-column uk-visible@s uk-border-circle">
                   <Img className=" uk-border-circle" fluid={product.image} alt="Gaufres Bonbec" />
               </td>
               <td className="uk-text-center">{product.name}</td>
               <td className="uk-text-center">${(product.price).toFixed(2)}</td>
               <td className="uk-text-center">
               <div class="quantity uk-text-center" style={{display: "inline-flex"}}>
                   <a onClick={(e)=> this.handleAdd(product)}>
                   <input value={product.qty}/>
                   </a>
                   <div class="quantity-nav">
                   <a class="quantity-button quantity-up" onClick={(e)=> this.handleAdd(product)}>+</a>
                   <a class={product.qty < 1 ? "quantity-button quantity-down disabled" : "quantity-button quantity-down active"} onClick={(e)=> this.handleSubstract(product)} disabled={product.qty < 1}>-</a>
                   </div>
               </div>
               </td>
               <td className="uk-text-right">
                   {discount > 0 ?
                   <><s>${totalWithTax}</s>
                       </> :
                       <>${totalWithTax}
                           </>} {discount > 0 &&
                           <>
                               <br/><span class="uk-text-success">-${discount}</span>
                               <br/>${totalWithDiscount}
                               </> }
               </td>
               </>
       </tr>

    );
  }
}

export default Cart;
