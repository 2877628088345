import React from 'react'
import Img from "gatsby-image"
import { injectIntl } from "gatsby-plugin-intl"


class Discuss extends React.Component {
    constructor(props) {
      super(props);
      this.handleShowCart = this.handleShowCart.bind(this);
    }

     handleShowCart() {
        this.props.onShowCart();
      }

    render() {
      const intl = this.props.intl;
      const images = this.props.images;
      return (
        <>
        <div class="uk-flex-middle" data-uk-grid>
          <div class="uk-width-1-3@s">
            <Img fluid={images.imageBox} alt="Gaufres Bonbec" />
          </div>
          <div class="uk-width-2-3@s uk-padding-large uk-flex-first@s uk-text-center ">
              <h2>{intl.formatMessage({ id: "discuss-section-title" })}</h2>
              <p>{intl.formatMessage({ id: "discuss-section-title-2" })}</p>
              <p>{intl.formatMessage({ id: "discuss-section-content-1"})}</p>
              <button class="uk-button uk-button-default submit-button"><a href="https://m.me/gaufresbonbec" target="_blank">{intl.formatMessage({ id: "discuss-section-content-2" })}</a></button>
            </div>
        </div>
        </>
        );
      }
}

export default Discuss;
