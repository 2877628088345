import React from "react"
import { navigateTo } from "gatsby-link"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class CheckoutOrder extends React.Component {
	constructor(props) {
	super(props);
	this.handleNewOrder = this.handleNewOrder.bind(this);
	this.ref = React.createRef();

  if (typeof window !== 'undefined') {
    this.state = {
  		sentOrder: false,
  		order: this.props.emailSummary,
  		name: localStorage.getItem('name')  ? localStorage.getItem('name') : '',
  		email: localStorage.getItem('email')  ? localStorage.getItem('email') : '',
  		phone: localStorage.getItem('phone')  ? localStorage.getItem('phone') : '',
  		messenger: localStorage.getItem('messenger')  ? localStorage.getItem('messenger') : '',
  		deliverydate: localStorage.getItem('deliverydate')  ? localStorage.getItem('deliverydate') : '',
  		payment: localStorage.getItem('payment')  ? localStorage.getItem('payment') : '',
  		address: localStorage.getItem('address')  ? localStorage.getItem('address') : '',
  		message: localStorage.getItem('message')  ? localStorage.getItem('message') : '',
  		order: localStorage.getItem('order')  ? localStorage.getItem('order') : ''
  	};
  } else {
    this.state = {
  		sentOrder: false
  	};
  }
}
componentWillReceiveProps(nextProps) {
	let updatedEmailSummary = nextProps.emailSummary
  this.setState({
		order: updatedEmailSummary
	});
}

	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value,
		 });
		localStorage.setItem([e.target.name], e.target.value);
		console.log(this.state);

	};

	handleSubmit = e => {
		e.preventDefault();
		localStorage.removeItem('message');
		localStorage.removeItem('deliverydate');
		localStorage.removeItem('order');
		const form = e.target;
		this.setState({
			sentOrder: true,
			deliverydate: '',
			message: '',
			order: ''
		 });
		console.log(this.state);
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({
				"form-name": form.getAttribute("name"),
				...this.state
			})
		})
		.then(() => navigateTo(form.getAttribute("action")))
		.catch(error => alert(error));
		setTimeout(() => {
		this.props.onOrderSubmit();
		console.log("ooooooorder");

	}, 500);
		console.log(this.state);
	};

	handleNewOrder() {
		localStorage.removeItem('message');
		localStorage.removeItem('deliverydate');
		localStorage.removeItem('order');
		console.log("new order");
		this.setState({
			sentOrder: false
		});
    this.props.onNewOrder();
   }


  render() {
		const intl = this.props.intl;
		const emailSummary = this.props.emailSummary;
		const minDate = new Date();

    return (
			<>
			{!this.state.sentOrder ?
			<form
			name="order"
			method="post"
			action={this.handleThankyou}
			data-netlify="true"
			data-netlify-honeypot="bot-field"
			onSubmit={this.handleSubmit}
			className="uk-grid-small"
			data-uk-grid>
			<input type="hidden" name="form-name" value="name" />
			<p hidden>
				<label>
					Don’t fill this out:{" "}
					<input name="bot-field" onChange={this.handleChange} />
				</label>
			</p>
			<div class="uk-width-1-2@s">
				<label class="uk-form-label" for="form-stacked-name">{intl.formatMessage({ id: "name" })}*</label>
				<div class="uk-form-controls">
						<input class="uk-input" id="form-stacked-name" type="text" name="name" placeholder="" value={this.state.name} onChange={this.handleChange} required/>
				</div>
	    </div>
	    <div class="uk-width-1-2@s">
				<label class="uk-form-label" for="form-stacked-mail">{intl.formatMessage({ id: "email" })}*</label>
				<div class="uk-form-controls">
						<input class="uk-input" id="form-stacked-mail" type="mail" name="email" placeholder="" value={this.state.email} onChange={this.handleChange} required/>
				</div>
	    </div>
			<div class="uk-width-1-2@s">
				<label class="uk-form-label" for="form-stacked-phone">{intl.formatMessage({ id: "phone" })}</label>
				<div class="uk-form-controls">
						<input class="uk-input" id="form-stacked-phone" type="phone" name="phone" placeholder="" value={this.state.phone} onChange={this.handleChange}/>
				</div>
	    </div>
	    <div class="uk-width-1-2@s">
				<label class="uk-form-label" for="form-stacked-messenger">{intl.formatMessage({ id: "messenger" })}</label>
				<div class="uk-form-controls">
						<input class="uk-input" id="form-stacked-messenger" type="text" name="messenger" placeholder="" value={this.state.messenger} onChange={this.handleChange}/>
				</div>
	    </div>

			<div class="uk-width-1-2@s">
				<label class="uk-form-label" for="form-stacked-deliverydate">{intl.formatMessage({ id: "deliverydate" })}*</label>
        <div>
						<select class="uk-select" id="form-stacked-deliverydate" name="deliverydate" value={this.state.deliverydate} onChange={this.handleChange} required>
								<option value={intl.formatMessage({ id: "23dec" })}>{intl.formatMessage({ id: "23dec" })}</option>
						</select>
				</div>
	    </div>
	    <div class="uk-width-1-2@s">
				<label class="uk-form-label" for="form-stacked-payment">{intl.formatMessage({ id: "payment" })}*</label>
				<div>
						<select class="uk-select" id="form-stacked-payment" name="payment" value={this.state.payment} onChange={this.handleChange} required>
								<option value={intl.formatMessage({ id: "interac" })}>{intl.formatMessage({ id: "interac" })}</option>
								<option value={intl.formatMessage({ id: "paypal" })}>{intl.formatMessage({ id: "paypal" })}</option>
								<option value={intl.formatMessage({ id: "cash" })}>{intl.formatMessage({ id: "cash" })}</option>
						</select>
				</div>
	    </div>
			<div class="uk-width-1-1@s">
				<label class="uk-form-label" for="form-stacked-address">{intl.formatMessage({ id: "address" })}*</label>
				<div class="uk-form-controls">
						<textarea class="uk-textarea" id="form-stacked-address" rows="2" name="address" placeholder="" value={this.state.address} onChange={this.handleChange} required></textarea>
				</div>
	    </div>
			<div class="uk-width-1-1@s">
				<label class="uk-form-label" for="form-stacked-message">{intl.formatMessage({ id: "message" })}</label>
				<div class="uk-form-controls">
						<textarea class="uk-textarea" id="form-stacked-message" rows="3" name="message" placeholder='' value={this.state.message} onChange={this.handleChange}></textarea>
				</div>
	    </div>
			<input type="hidden" class="uk-textarea" id="form-stacked-order" rows="10" name="order" placeholder='' value={emailSummary} value={this.state.order} onChange={this.handleChange}/>
			<div class="uk-width-1-2@s">
	        <button class="uk-button uk-button-default submit-button" type="submit">{intl.formatMessage({ id: "send" })}</button>
	    </div>
			<div class="uk-width-1-2@s">
					{intl.formatMessage({ id: "fb-questions-1" })}<br/><a href="https://m.me/gaufresbonbec" target="_blank">{intl.formatMessage({ id: "fb-questions-2" })}</a>
	    </div>
		</form>
		 :
		<div className="uk-text-center" ref={this.ref}>
			<div class="uk-width-1-1@s">
					<h2 className="uk-text-success">{intl.formatMessage({ id: "sent-order" })}</h2>
					<p>{intl.formatMessage({ id: "fb-questions-1" })}<br/><a href="https://m.me/gaufresbonbec" target="_blank">{intl.formatMessage({ id: "fb-questions-2" })}</a></p>
			</div>
			<div class="uk-flex uk-flex-center uk-padding-small">
				<div className="uk-width-1-2@s">
					<button class="inverted-button uk-button uk-button-default submit-button" onClick={this.handleNewOrder}>{intl.formatMessage({ id: "new-order" })}</button>
				</div>
			</div>
		</div>
		}
		</>
    )
  }
}

export default CheckoutOrder
