import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"
import Story from "../components/story"
import Waffles from "../components/waffles"
import Slider from "../components/slider"
import Subscribe from "../components/subscribe"
import "../components/layout.css"


const IndexPage = ({ intl, data }) => {
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "seo" })}/>
      <Header/>
      <Story/>
      <Waffles/>
      <Slider/>
      <Subscribe/>
    </Layout>
  )
}
export default injectIntl(IndexPage)
