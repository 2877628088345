import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { injectIntl } from "gatsby-plugin-intl"

const Slider = ({ intl }) => {
    const data = useStaticQuery(graphql`
      query {
        Social1: file(relativePath: { eq: "Social1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
        Social2: file(relativePath: { eq: "Social2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
        Social4: file(relativePath: { eq: "Social4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
        Social5: file(relativePath: { eq: "Social5.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
        Social6: file(relativePath: { eq: "Social6.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `)
  return (

    <div className="uk-section inverted">
        <div className="uk-container">
        <div class="uk-child-width-1-3@m" >
          <h1>{intl.formatMessage({ id: "slider-section-headline" })}</h1>
        </div>
        <div data-uk-slider="center: true;autoplay: true;autoplay-interval: 3000" className="uk-margin-right uk-margin-left">

        <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">

            <ul class="uk-slider-items uk-child-width-1-2 uk-grid">
                <li>
                    <div class="uk-card">
                        <div class="uk-card-media-top">
                            <Img fluid={data.Social1.childImageSharp.fluid} alt="Gaufres Bonbec"/>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="uk-card">
                        <div class="uk-card-media-top">
                            <Img fluid={data.Social4.childImageSharp.fluid} alt="Gaufres Bonbec"/>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="uk-card">
                        <div class="uk-card-media-top">
                            <Img fluid={data.Social5.childImageSharp.fluid} alt="Gaufres Bonbec"/>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="uk-card">
                        <div class="uk-card-media-top">
                            <Img fluid={data.Social6.childImageSharp.fluid} alt="Gaufres Bonbec"/>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="uk-card">
                        <div class="uk-card-media-top">
                            <Img fluid={data.Social2.childImageSharp.fluid} alt="Gaufres Bonbec"/>
                        </div>
                    </div>
                </li>

            </ul>

            <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
            <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>

        </div>

        <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>

    </div>
        </div>
    </div>
    );
  };
export default injectIntl(Slider)
