import React from 'react';

class Summary extends React.Component {

    render() {
      const receipt =  this.props.receipt;
      const intl = this.props.intl;
      return (
        <>
        <div class="summary uk-card-body summary uk-card-body uk-padding-remove-left">
          <div class="uk-grid-small" data-uk-grid>
            <div class="uk-width-expand uk-text-bold" data-uk-leader>{receipt.totalDiscount > 0 ? intl.formatMessage({ id: "total-final" }) : intl.formatMessage({ id: "totalWithTaxes" })}</div>
            <div class="uk-text-bolder">
            {receipt.totalDiscount > 0 && <><s>${receipt.totalWithTaxes}</s><br/></>}
            ${receipt.totalFinal}
            </div>
          </div>
          <ul data-uk-accordion>
              <li>
                  <a class="uk-accordion-title uk-width-expand" href="#">{intl.formatMessage({ id: "details" })}</a>
                  <div class="uk-accordion-content">
                      <div class="uk-grid-small" data-uk-grid>
                          <div class="uk-width-expand uk-text-muted" data-uk-leader>{this.props.translations.total}</div>
                          <div>${receipt.totalBeforeTaxes}</div>
                      </div>
                      <div class="uk-grid-small" data-uk-grid>
                          <div class="uk-width-expand uk-text-muted" data-uk-leader>{intl.formatMessage({ id: "delivery-fee" })}</div>
                          <div>${receipt.deliveryFeeBeforeTax}</div>
                      </div>
                      <div class="uk-grid-small" data-uk-grid>
                          <div class="uk-width-expand uk-text-muted" data-uk-leader>{this.props.translations.taxes}</div>
                          <div>${receipt.totalTaxes}</div>
                      </div>
                      <div class="uk-grid-small" data-uk-grid>
                          <div class="uk-width-expand uk-text-muted uk-text-bold" data-uk-leader>{this.props.translations.totalWithTaxes}</div>
                          <div>${receipt.totalWithTaxes}</div>
                      </div>

                        {receipt.totalDiscount > 0 &&
                        <div class="uk-grid-small" data-uk-grid>
                            <div class="uk-width-expand uk-text-muted" data-uk-leader>{this.props.translations.discount}</div>
                            <div class="uk-text-success">-${receipt.totalDiscount}</div>
                        </div>
                        }
                        {receipt.totalCodePromo > 0 &&
                        <div class="uk-grid-small" data-uk-grid>
                            <div class="uk-width-expand uk-text-muted" data-uk-leader>{intl.formatMessage({ id: "code-promo" })}</div>
                            <div class="uk-text-success">-${receipt.totalCodePromo}</div>
                        </div>
                        }
                        {(receipt.totalDiscount > 0 || receipt.totalCodePromo > 0) ?
                        <div class="uk-grid-small" data-uk-grid>
                            <div class="uk-width-expand uk-text-muted uk-text-bold" data-uk-leader>{intl.formatMessage({ id: "total-final" })}</div>
                            <div class="uk-text-bold">${receipt.totalFinal}</div>
                        </div>
                        : ''
                        }
                  </div>
              </li>
          </ul>
        </div>
        </>
        );
      }
}

export default Summary;
