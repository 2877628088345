import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { useIntl,injectIntl } from "gatsby-plugin-intl"
import Order from "../components/order"

import { useLocation } from '@reach/router';
import queryString from 'query-string';

const Waffles = () => {
   const intl = useIntl();
    const data = useStaticQuery(graphql`
      query {
        Gaufre1: file(relativePath: { eq: "Gaufre1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
        Gaufre2: file(relativePath: { eq: "Gaufre2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
        Gaufre3: file(relativePath: { eq: "Gaufre3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
        Gaufre4: file(relativePath: { eq: "Gaufre4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
          Box: file(relativePath: { eq: "box.png" }) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          },
          Social2: file(relativePath: { eq: "Social2.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          },
        }
    `);
    const productList = [
      {
        id: 1,
        image: data.Gaufre1.childImageSharp.fluid,
        name: intl.formatMessage({ id: "waffles-section-waffle1-title" }),
        cartname: intl.formatMessage({ id: "waffles-section-waffle1-cartname" }),
        price_label: intl.formatMessage({ id: "waffles-section-waffle1-prices" }),
        price: 3,
        description: intl.formatMessage({ id: "waffles-section-waffle1-content" }),
        qty: 0,
        discount: 0
      },
      {
        id: 2,
        image: data.Gaufre2.childImageSharp.fluid,
        name: intl.formatMessage({ id: "waffles-section-waffle2-title" }),
        cartname: intl.formatMessage({ id: "waffles-section-waffle2-cartname" }),
        price_label: intl.formatMessage({ id: "waffles-section-waffle2-prices" }),
        price: 3.5,
        description: intl.formatMessage({ id: "waffles-section-waffle2-content" }),
        qty: 0,
        discount: 0
      }
    ];
    const translations = {
      waffles: intl.formatMessage({ id: "waffle-name" }),
      wafflesHeadline: intl.formatMessage({ id: "waffles-section-headline" }),
      orderHeadline: intl.formatMessage({ id: "delivery-section-headline" }),
      price: intl.formatMessage({ id: "price" }),
      quantity: intl.formatMessage({ id: "quantity" }),
      order: intl.formatMessage({ id: "order" }),
      total: intl.formatMessage({ id: "total" }),
      taxes: intl.formatMessage({ id: "taxes" }),
      totalWithTaxes: intl.formatMessage({ id: "totalWithTaxes" }),
      totalWithDiscount: intl.formatMessage({ id: "totalWithDiscount" }),
      discount: intl.formatMessage({ id: "discount" }),
      promo: intl.formatMessage({ id: "promo" })
    };
    const images = {
      imageBox: data.Box.childImageSharp.fluid
    };

    const location = useLocation();
    const queryString = location.search;

    function handleTotalProductUpdate(count){
      console.log("count ="+count);
    }

  return (
      <Order
      productList={productList}
      translations={translations}
      images={images}
      intl={intl}
      onTotalProductUpdate={handleTotalProductUpdate}
      queryString={queryString}/>
    );
  };
export default injectIntl(Waffles)
