import React from "react";

function Addicon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 243.5 243.5"
      version="1.1"
      viewBox="0 0 243.5 243.5"
      xmlSpace="preserve"
    >
      <path d="M113.403 157.775a7.5 7.5 0 1014.92-1.543l-3.458-33.468a7.5 7.5 0 00-14.921 1.542l3.459 33.469zM160.338 164.464a7.502 7.502 0 008.231-6.689l3.458-33.468a7.499 7.499 0 00-6.689-8.231c-4.121-.422-7.806 2.57-8.232 6.689l-3.458 33.468a7.5 7.5 0 006.69 8.231zM102.266 197.064c-12.801 0-23.215 10.414-23.215 23.215 0 12.804 10.414 23.221 23.215 23.221s23.216-10.417 23.216-23.221c-.001-12.8-10.415-23.215-23.216-23.215zm0 31.436c-4.53 0-8.215-3.688-8.215-8.221 0-4.53 3.685-8.215 8.215-8.215 4.53 0 8.216 3.685 8.216 8.215-.001 4.533-3.686 8.221-8.216 8.221zM179.707 197.064c-12.801 0-23.216 10.414-23.216 23.215 0 12.804 10.415 23.221 23.216 23.221 12.802 0 23.218-10.417 23.218-23.221 0-12.8-10.416-23.215-23.218-23.215zm0 31.436c-4.53 0-8.216-3.688-8.216-8.221 0-4.53 3.686-8.215 8.216-8.215 4.531 0 8.218 3.685 8.218 8.215 0 4.533-3.687 8.221-8.218 8.221z"></path>
      <path d="M224.569 91.057a7.5 7.5 0 00-5.933-2.913H69.141l-6.277-24.141a7.5 7.5 0 00-7.259-5.612h-30.74a7.5 7.5 0 000 15h24.941l6.221 23.922c.034.15.073.299.116.446l23.15 89.022a7.5 7.5 0 007.259 5.612h108.874a7.5 7.5 0 007.259-5.612l23.211-89.25a7.508 7.508 0 00-1.327-6.474zm-34.943 86.338H92.35l-19.309-74.25h135.894l-19.309 74.25z"></path>
      <path d="M135.674 74.826a7.478 7.478 0 005.303 2.197c1.92 0 3.839-.732 5.303-2.197l24.28-24.28a7.5 7.5 0 10-10.606-10.606l-11.468 11.468.002-43.907A7.5 7.5 0 00140.989 0h-.001a7.5 7.5 0 00-7.5 7.499l-.002 43.925-11.468-11.468a7.5 7.5 0 00-10.606 0 7.5 7.5 0 000 10.606l24.262 24.264z"></path>
    </svg>
  );
}

export default Addicon;
